import { PolicyColumnKey } from "./policiesColumns.config"
import {
    AvailableModalityCoverageStatus,
    AvailablePlanStatus,
} from "./coverage.config"

export enum BenefitsStructureConfigFilter {
    greaterThan = "greaterThan",
    lessThan = "lessThan",
    equals = "equals",
}

export enum BulkOverrideTab {
    allowed = "allowed",
    requiredDataMissing = "requiredDataMissing",
}

export const OVERRIDES_CONFIG = {
    defaultBenefitsStructure: "Other",

    get notAllowedPlanStatusValues(): AvailablePlanStatus[] {
        return [AvailablePlanStatus.unknown]
    },

    get notAllowedModalityCoverageStatusValues(): AvailableModalityCoverageStatus[] {
        return [AvailableModalityCoverageStatus.unknown]
    },

    bulkOverrides: {
        defaultPreviewCount: 2,
        creationChunkSize: 25,
        sleepAtTheEndSeconds: 10,

        allowedResponseStatusCodes: [200, 206],

        get fieldsIgnoreSameValueCheck(): (keyof ICoverageOverrideFormData)[] {
            return [
                "benefitStructure",
                "customBenefitStructure",
                "copayment",
                "coinsurance",
            ]
        },

        get allowedOverrideFields(): Partial<
            Record<PolicyColumnKey, keyof ICoverageOverrideFormData>
        > {
            // Order matters, since it's wrapped there
            // So it should be one from left, one from right, one from left etc
            return {
                [PolicyColumnKey.COPAYMENT]: "copayment", // left column
                [PolicyColumnKey.THIRD_PARTY_PAYER]: "thirdPartyPayer", // right
                [PolicyColumnKey.COINSURANCE]: "coinsurance", // left
                [PolicyColumnKey.PLAN_TYPE]: "planType", // right
                [PolicyColumnKey.TOTAL_OOP_MAX]: "oopMax", // left
                [PolicyColumnKey.REMAINING_OOP_MAX]: "remainingOopMax", // right
                [PolicyColumnKey.TOTAL_DEDUCTIBLE]: "deductible", // left
                [PolicyColumnKey.REMAINING_DEDUCTIBLE]: "remainingDeductible", // right
                [PolicyColumnKey.BENEFIT_STRUCTURE]: "benefitStructure", // left
            }
        },

        get ignoredValueComputationFields(): PolicyColumnKey[] {
            return [
                PolicyColumnKey.REMAINING_OOP_MAX,
                PolicyColumnKey.REMAINING_DEDUCTIBLE,
                PolicyColumnKey.BENEFIT_STRUCTURE,
            ]
        },

        get allowedOverrideFieldsConfirmationNotice(): PolicyColumnKey[] {
            return [
                PolicyColumnKey.REMAINING_OOP_MAX,
                PolicyColumnKey.REMAINING_DEDUCTIBLE,
            ]
        },

        get availableTabs(): IBulkOverrideAvailableTab[] {
            return [
                {
                    key: BulkOverrideTab.allowed,
                    label: "Allowed for Bulk Override",
                },
                {
                    key: BulkOverrideTab.requiredDataMissing,
                    label: "Missing Required Override Data",
                },
            ]
        },
    },

    tooltips: {
        memberObligation:
            "Member obligation cannot be overridden unless the benefit structure is set to Other with a description",
        payerObligation:
            "Payer obligation cannot be overridden unless the benefit structure is set to Other with a description",
    },

    validations: {
        noDataProvided:
            "No data was provided for override. Please review values and try again.",
        otherBenefitStructureEqualKnown:
            "Other benefit structure description can not equal to known structure. Please review values and try again.",
        memberPayerObligations:
            "Member and Payer obligation cannot be overridden unless the benefit structure is set to Other with a description. Please review and resubmit.",
    },

    get benefitStructureDependentFieldsConfig(): Record<
        keyof IBenefitsStructureDependingValues,
        keyof IBenefitsStructureDependingValues
    > {
        return {
            copayment: "copayment",
            coinsurance: "coinsurance",
            oopMax: "oopMax",
            deductible: "deductible",
        }
    },

    get benefitsStructuresConfig(): IBenefitsStructureConfig[] {
        return [
            {
                title: "Copay, no deductible, no OOP Max",
                subTitle: "Patient pays the copay amount",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "coinsurance",
                    "deductible",
                    "remainingDeductible",
                    "oopMax",
                    "remainingOopMax",
                ],
            },
            {
                title: "Copay, no deductible, with OOP Max",
                subTitle:
                    "Patient pays the copay amount until out-of-pocket max is met",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "coinsurance",
                    "deductible",
                    "remainingDeductible",
                ],
            },
            {
                title: "Coinsurance after deductible, no OOP Max",
                subTitle:
                    "Patient covers full session cost until deductible is met. Once met, patient will pay coinsurance amount",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "copayment",
                    "oopMax",
                    "remainingOopMax",
                ],
            },
            {
                title: "Coinsurance after deductible, with OOP Max",
                subTitle:
                    "Patient covers full session cost until deductible is met. Once met, patient will pay coinsurance amount until out-of-pocket max is met",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: ["copayment"],
            },
            {
                title: "Copay after deductible, no OOP Max",
                subTitle:
                    "Patient covers full session cost until deductible is met.  Once met, patient will pay the copay amount",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "coinsurance",
                    "oopMax",
                    "remainingOopMax",
                ],
            },
            {
                title: "Copay after deductible, with OOP Max",
                subTitle:
                    "Patient covers full session cost until deductible is met.  Once met, patient will pay the copay amount until out-of-pocket max is met",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: ["coinsurance"],
            },
            {
                title: "Coinsurance, no deductible, no OOP Max",
                subTitle: "Patient pays coinsurance",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "copayment",
                    "deductible",
                    "remainingDeductible",
                    "oopMax",
                    "remainingOopMax",
                ],
            },
            {
                title: "Coinsurance, no deductible, with OOP Max",
                subTitle:
                    "Patient pays coinsurance until out-of-pocket max is met",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "copayment",
                    "deductible",
                    "remainingDeductible",
                ],
            },
            {
                title: "Copay and coinsurance after deductible, no OOP Max",
                subTitle:
                    "Patient covers full session cost until deductible is met.  Once met, patient pays both the copay and coinsurance. ",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "oopMax",
                    "remainingOopMax",
                ],
            },
            {
                title: "Copay and coinsurance after deductible, with OOP Max",
                subTitle:
                    "Patient covers full session cost until deductible is met.  Once met, patient pays both the copay and coinsurance until out-of-pocket max is met.",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [],
            },
            {
                title: "Copay and coinsurance, no deductible, no OOP Max",
                subTitle: "Patient pays both the copay and coinsurance.",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "deductible",
                    "remainingDeductible",
                    "oopMax",
                    "remainingOopMax",
                ],
            },
            {
                title: "Copay and coinsurance, no deductible, with OOP Max",
                subTitle:
                    "Patient pays both the copay and coinsurance until out-of-pocket max is met",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.oopMax,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "deductible",
                    "remainingDeductible",
                ],
            },
            {
                title: "Fully covered",
                subTitle: "Patient does not have to pay.",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "coinsurance",
                    "copayment",
                    "deductible",
                    "remainingDeductible",
                    "oopMax",
                    "remainingOopMax",
                ],
            },
            {
                title: "Fully covered after deductible",
                subTitle: "Patient does not have to pay.",
                requiredFields: [
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.deductible,
                        valueOperator:
                            BenefitsStructureConfigFilter.greaterThan,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.coinsurance,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                    {
                        name: OVERRIDES_CONFIG
                            .benefitStructureDependentFieldsConfig.copayment,
                        valueOperator: BenefitsStructureConfigFilter.equals,
                    },
                ],
                notApplicableOnBeingExistingResponseFields: [
                    "coinsurance",
                    "copayment",
                    "oopMax",
                    "remainingOopMax",
                ],
            },
            {
                title: OVERRIDES_CONFIG.defaultBenefitsStructure,
                default: true,
                requiredFields: [],
            },
        ]
    },
}
