import { PolicyModality } from "nirvana-react-elements"

import {
    CoveragePortalFlagType,
    POLICIES_CONFIG,
    PoliciesViewType,
    PolicyDenialRisk,
    PolicyHeaderDragDirection,
    ResetBenefitStatus,
} from "../config/policies.config"
import {
    CALCULATOR_CONFIG,
    ExportDataSource,
} from "../config/calculator.config"
import { CsvHelper } from "./csv.helper"
import { UtilHelper } from "./util.helper"
import { POLICIES_COLUMNS_CONFIG } from "../config/policiesColumns.config"
import { ROUTES_CONFIG } from "../config/routes.config"
import { COVERAGE_CONFIG } from "../config/coverage.config"

export class PoliciesHelper {
    /**
     * Get default reports columns
     */
    static getDefaultReportsColumns(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return PoliciesHelper.processAdditionalColumns(
            POLICIES_COLUMNS_CONFIG.reportsPoliciesColumns,
            "ignoreReports",
            "reportsOrder",
            "isReportsEnabled",
            availableModalities
        )
    }

    /**
     * Get default historical checks columns
     */
    static getDefaultHistoricalChecksColumns(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return PoliciesHelper.processAdditionalColumns(
            POLICIES_COLUMNS_CONFIG.historicalPoliciesColumns,
            "ignoreHistoricalPolicies",
            "historicalPoliciesOrder",
            "isHistoricalPoliciesEnabled",
            availableModalities
        )
    }

    /**
     * Get default overrides columns
     */
    static getDefaultOverridesColumns(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return PoliciesHelper.processAdditionalColumns(
            POLICIES_COLUMNS_CONFIG.overridesPoliciesColumns,
            "ignoreOverrides",
            "overridesOrder",
            "isOverridesEnabled",
            availableModalities
        )
    }

    /**
     * Get default plan year resets columns
     */
    static getDefaultPlanYearResetsColumns(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return PoliciesHelper.processAdditionalColumns(
            POLICIES_COLUMNS_CONFIG.planYearResetsPoliciesColumns,
            "ignorePlanYearResets",
            "planYearResetsOrder",
            "isPlanYearResetsEnabled",
            availableModalities
        )
    }

    /**
     * Move column to some other place in list
     */
    static changeSingleColumnOrdering(
        columns: IPolicyColumnConfiguration[],
        columnLabelMoveSource: string,
        columnLabelMoveTarget: string,
        direction: PolicyHeaderDragDirection
    ): IPolicyColumnConfiguration[] {
        // First find and remove needed column
        const source = columns.find(
            item => item.label === columnLabelMoveSource
        )

        if (!source) {
            return columns
        }

        const newColumns = columns.filter(item => item.label !== source.label)

        // Find target column index
        const targetIndex = newColumns.findIndex(
            item => item.label === columnLabelMoveTarget
        )

        if (targetIndex < 0) {
            return columns
        }

        // Adjust based on drag direction
        // If after add 1
        newColumns.splice(
            targetIndex +
                (direction === PolicyHeaderDragDirection.AFTER ? 1 : 0),
            0,
            source
        )

        return newColumns
    }

    /**
     * Get mapped policy column value based on coverage check
     */
    static getMappedColumnValue = (
        coverageCheckHistory: ICoverageCheckHistory,
        columnConfig: IPolicyColumnConfiguration,
        emptyColumnValue: string | null = null,
        jsxSupportedFormatting = true,
        onlyCheckResult = false
    ): JSX.Element | string | null => {
        let columnValue: any = columnConfig.getCustomValue
            ? columnConfig.getCustomValue(coverageCheckHistory)
            : onlyCheckResult
            ? coverageCheckHistory.coverageResult
            : columnConfig.source
            ? coverageCheckHistory[columnConfig.source]
            : undefined

        emptyColumnValue = columnConfig.emptyColumnValue || emptyColumnValue

        if (
            !columnValue &&
            !(
                columnConfig.alternativeSource &&
                columnConfig.alternativeSourcePropertyPath
            )
        ) {
            return emptyColumnValue
        }

        // Go through each value path and assign it's value to column value, break down by "." so we can support nested properties of objects
        // Only in case there's no custom value
        if (!columnConfig.getCustomValue && columnConfig.sourcePropertyPath) {
            columnConfig.sourcePropertyPath.split(".").forEach(pathPart => {
                try {
                    columnValue = columnValue?.[pathPart]
                } catch (e) {
                    columnValue = emptyColumnValue
                }
            })
        }

        // IF resulting value is undefined or null -> assign empty string to it
        if (typeof columnValue === "undefined" || columnValue === null) {
            columnValue = emptyColumnValue
        }

        // Try with alternative source
        if (
            columnValue === emptyColumnValue &&
            columnConfig.alternativeSource &&
            columnConfig.alternativeSourcePropertyPath
        ) {
            return PoliciesHelper.getMappedColumnValue(
                coverageCheckHistory,
                {
                    ...columnConfig,
                    source: columnConfig.alternativeSource,
                    sourcePropertyPath:
                        columnConfig.alternativeSourcePropertyPath,
                    alternativeSource: undefined,
                    alternativeSourcePropertyPath: undefined,
                },
                emptyColumnValue,
                jsxSupportedFormatting,
                onlyCheckResult
            )
        }

        return PoliciesHelper.getFormattedColumnValue(
            columnValue,
            columnConfig,
            emptyColumnValue,
            jsxSupportedFormatting,
            coverageCheckHistory.coverageResult
        )
    }

    /**
     * Get formatted column value based on config
     */
    static getFormattedColumnValue(
        columnValue: any,
        columnConfig: IPolicyColumnConfiguration,
        emptyColumnValue: string | null = null,
        jsxSupportedFormatting = true,
        coverageResultData?: ICoverageResult | null
    ): JSX.Element | string | null {
        if (
            columnConfig.formatting &&
            (columnValue !== emptyColumnValue ||
                columnConfig.formatOnEmptyColumn)
        ) {
            columnValue = CsvHelper.getFormattedColumnValue(
                columnValue,
                columnConfig.formatting,
                emptyColumnValue,
                jsxSupportedFormatting,
                coverageResultData
            )
        }

        try {
            return columnValue !== emptyColumnValue &&
                !columnConfig.ignoreValueCapitalization
                ? UtilHelper.capitalizeWordsInSentence(columnValue)
                : columnValue
        } catch (e) {
            return columnValue
        }
    }

    /**
     * Check if column cell is highlighted
     */
    static isColumnCellHighlighted(
        coverageCheckHistory: ICoverageCheckHistory,
        columnConfig: IPolicyColumnConfiguration
    ): boolean {
        let result: any = columnConfig.isHighlightedSource
            ? coverageCheckHistory[columnConfig.isHighlightedSource]
            : undefined

        if (!result) {
            return false
        }

        // Go through each value path and assign it's value to result, break down by "." so we can support nested properties of objects
        if (columnConfig.isHighlightedSourcePropertyPath) {
            const initialResult = result

            const pathVariants = Array.isArray(
                columnConfig.isHighlightedSourcePropertyPath
            )
                ? columnConfig.isHighlightedSourcePropertyPath
                : [columnConfig.isHighlightedSourcePropertyPath]

            for (const path of pathVariants) {
                result = initialResult

                for (const pathPart of path.split(".")) {
                    try {
                        result = result?.[pathPart]
                    } catch (e) {
                        result = undefined
                    }
                }

                if (result) {
                    break
                }
            }
        }

        return typeof result !== "undefined" && result !== null
    }

    /**
     * Get columns configurations specific to different modalities
     */
    static getModalitiesSpecificColumnsConfigurations(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IExtendedPolicyColumnConfiguration[] {
        let result: IExtendedPolicyColumnConfiguration[] = []

        for (const modality of availableModalities) {
            result = [
                ...result,

                ...POLICIES_COLUMNS_CONFIG.modalitySpecificColumns[modality],
            ]
        }

        return result
    }

    /**
     * Get policies list base url based on general view type
     */
    static getPoliciesListBaseUrl(viewType: PoliciesViewType): string {
        switch (viewType) {
            case PoliciesViewType.REPORTS:
                return ROUTES_CONFIG.reportsUrl

            case PoliciesViewType.HISTORY_SEARCH:
                return ROUTES_CONFIG.searchUrl

            case PoliciesViewType.OVERRIDES:
                return ROUTES_CONFIG.overridesUrl

            case PoliciesViewType.PLAN_YEAR_RESETS:
                return ROUTES_CONFIG.planYearResetsUrl
        }
    }

    /**
     * Get single policy base url based on general view type
     */
    static getSinglePolicyBaseUrl(viewType: PoliciesViewType): string {
        switch (viewType) {
            case PoliciesViewType.REPORTS:
                return ROUTES_CONFIG.reportsSinglePolicyUrl

            case PoliciesViewType.HISTORY_SEARCH:
                return ROUTES_CONFIG.searchSinglePolicyUrl

            case PoliciesViewType.OVERRIDES:
                return ROUTES_CONFIG.overridesSinglePolicyUrl

            case PoliciesViewType.PLAN_YEAR_RESETS:
                return ROUTES_CONFIG.planYearResetsSinglePolicyUrl
        }
    }

    /**
     * Convert columns configurations to CSV fields mappings
     * Supports only coverage api result mappings
     */
    static convertPolicyColumnsToCsvFieldsMappings(
        columns: IPolicyColumnConfiguration[]
    ): ICsvFieldsMapping[] {
        const result: ICsvFieldsMapping[] = []

        for (const column of columns) {
            const dataSource: ICsvDataFieldConfig | undefined =
                column.source === "coverageResult" && column.sourcePropertyPath
                    ? {
                          source: ExportDataSource.outputData,
                          valuePath: column.sourcePropertyPath,
                      }
                    : column.alternativeSource === "coverageResult" &&
                      column.alternativeSourcePropertyPath
                    ? {
                          source: ExportDataSource.outputData,
                          valuePath: column.alternativeSourcePropertyPath,
                      }
                    : undefined

            // Ensure there is an available source
            if (!dataSource) {
                continue
            }

            // In the case of a sourcePropertyPath and an alternativeSourcePropertyPath, include both
            // Okay if dataSource and alternativeDataSource end up the same
            const alternativeDataSource: ICsvDataFieldConfig | undefined =
                column.alternativeSource === "coverageResult" &&
                column.alternativeSourcePropertyPath
                    ? {
                          source: ExportDataSource.outputData,
                          valuePath: column.alternativeSourcePropertyPath,
                      }
                    : undefined

            result.push({
                header: column.label,
                dataSource,
                ...(alternativeDataSource ? { alternativeDataSource } : {}),
                formatting: column.formatting,
            })
        }

        return result
    }

    /**
     * Process additional global columns
     * Hide some of them, change order, or change default visibility
     */
    static processAdditionalColumns(
        baseColumns: IPolicyColumnConfiguration[],
        ignoreProperty: keyof Pick<
            IExtendedPolicyColumnConfiguration,
            | "ignoreHistoricalPolicies"
            | "ignoreReports"
            | "ignoreOverrides"
            | "ignorePlanYearResets"
        >,
        orderProperty: keyof Pick<
            IExtendedPolicyColumnConfiguration,
            | "historicalPoliciesOrder"
            | "reportsOrder"
            | "overridesOrder"
            | "planYearResetsOrder"
        >,
        enabledOverrideProperty: keyof Pick<
            IExtendedPolicyColumnConfiguration,
            | "isHistoricalPoliciesEnabled"
            | "isReportsEnabled"
            | "isOverridesEnabled"
            | "isPlanYearResetsEnabled"
        >,
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        // Make a copy
        baseColumns = [...baseColumns]

        const additionalColumns = [
            ...PoliciesHelper.getModalitiesSpecificColumnsConfigurations(
                availableModalities
            ),
        ].filter(item => !item[ignoreProperty])

        // Add columns with needed order to needed place
        for (const column of additionalColumns) {
            const orderIndex = column[orderProperty]

            if (typeof orderIndex === "undefined") {
                continue
            }

            baseColumns.splice(orderIndex, 0, column)
        }

        const result = [
            ...baseColumns,

            // Add columns without needed order to the end
            ...additionalColumns.filter(
                item => typeof item[orderProperty] === "undefined"
            ),
        ]

        // Override default visibility and being default by config from column itself
        return result.map(item => {
            const enabledOverride = item[enabledOverrideProperty]

            const isEnabled =
                typeof enabledOverride !== "undefined"
                    ? enabledOverride
                    : item.isEnabled

            const isDefaultColumn =
                typeof enabledOverride !== "undefined"
                    ? enabledOverride
                    : item.isDefaultColumn

            return {
                ...item,

                isEnabled,
                isDefaultColumn,
            }
        })
    }

    /**
     * Calculate value for aggregation by status code
     */
    static getStatusCodeAggregationValue(
        data: IGetCoverageChecksAggregationsStatusCodes,
        config: IReportsStatusCodesWidgetConfig
    ): number {
        if (!config.aggregationRules?.length) {
            return 0
        }

        let result = 0

        for (const rule of config.aggregationRules) {
            if (rule.statusCode && !rule.errorCode) {
                result += data.aggregations[rule.statusCode]?.total || 0

                continue
            }

            // Below we are mapping through error code, so need to be set
            if (!rule.errorCode) {
                continue
            }

            // That is set on rule, or all if not set
            const neededStatusCodes = rule.statusCode
                ? [rule.statusCode]
                : Object.keys(data.aggregations)

            for (const statusCode of neededStatusCodes) {
                result +=
                    data.aggregations[statusCode]?.detail?.[rule.errorCode] || 0
            }
        }

        return result
    }

    /**
     * Map list of flags to denial risk
     */
    static getPolicyDenialRisk(
        flags?: CoveragePortalFlagType[] | null
    ): PolicyDenialRisk {
        if (!flags?.length) {
            return PolicyDenialRisk.NONE
        }

        const mappings = flags.map(
            item => POLICIES_CONFIG.flagTypeMappings[item]
        )

        const maxSeverity = Math.max(...mappings.map(item => item.severity))

        if (maxSeverity >= POLICIES_CONFIG.highAttentionFlagSeverityCheck) {
            return PolicyDenialRisk.HIGH
        } else if (
            maxSeverity >= POLICIES_CONFIG.mediumAttentionFlagSeverityCheck
        ) {
            return PolicyDenialRisk.MEDIUM
        }

        return PolicyDenialRisk.LOW
    }

    /**
     * Get demographics difference between input and result
     */
    static getDemographicsDifference(
        inputData: ICoverageInputData | IGetCoverageEstimateData,
        resultDemographics?: ICoverageResultDemographics
    ): Partial<ICoverageResultDemographics> {
        if (!resultDemographics) {
            return {}
        }

        return Object.keys(resultDemographics).reduce((memo, key) => {
            let inputValue = inputData[key]
            const demographicsValue = resultDemographics[key]

            // Don't process attributes like address and gender
            // Check if input required values were provided
            if (
                !demographicsValue ||
                (!inputValue &&
                    CALCULATOR_CONFIG.demographicsDifference.inputRequiredKeys.includes(
                        key as keyof ICoverageResultDemographics
                    )) ||
                CALCULATOR_CONFIG.demographicsDifference.ignoreKeys.includes(
                    key as keyof ICoverageResultDemographics
                )
            ) {
                return memo
            }

            if (
                inputValue?.toLowerCase()?.trim() !==
                demographicsValue?.toLowerCase()?.trim()
            ) {
                memo[key] = demographicsValue
            }

            return memo
        }, {})
    }

    /**
     * Convert plan year resets status to chart values
     */
    static mapPlanYearResetsStatusToChartValues(
        resetBenefitStatus: ResetBenefitStatus,
        value: number,
        valueComparePercentage: number
    ): IPoliciesAggregatedChartValues {
        return {
            label: COVERAGE_CONFIG.selectRenderedResetBenefitStatus[
                resetBenefitStatus
            ].displayValue,

            color: POLICIES_CONFIG.planYearResetsStatusColors[
                resetBenefitStatus
            ],
            bordered: resetBenefitStatus === ResetBenefitStatus.NOT_DETECTED,

            value,
            percentage: UtilHelper.getPercentageValue(
                value,
                valueComparePercentage
            ),
        }
    }

    /**
     * Get relative to today saved configuration filters
     */
    static getRelativeToTodaySavedConfigurationFilters(
        configuration: IPoliciesSavedConfiguration
    ): IPoliciesListFiltersData {
        let filtersOverrides: IPoliciesListFiltersData = {}

        const {
            dateFrom,
            dateTo,
            nextAppointmentDateFrom,
            nextAppointmentDateTo,
        } = configuration.filters

        // Make sure to adjust dates to be relative to the date when configuration was created
        if (configuration.isRelativeDates && (dateFrom || dateTo)) {
            const { dateFrom: newDateFrom, dateTo: newDateTo } =
                UtilHelper.calculateRelativeToTodayDateRange(
                    configuration.createdAt,
                    dateFrom,
                    dateTo
                )

            filtersOverrides.dateFrom = newDateFrom
                ? UtilHelper.dateToMysqlFormat(newDateFrom)
                : undefined

            filtersOverrides.dateTo = newDateTo
                ? UtilHelper.dateToMysqlFormat(newDateTo)
                : undefined
        }

        // Make sure to adjust dates to be relative to the date when configuration was created
        if (
            configuration.isRelativeNextAppointmentDates &&
            (nextAppointmentDateFrom || nextAppointmentDateTo)
        ) {
            const { dateFrom: newDateFrom, dateTo: newDateTo } =
                UtilHelper.calculateRelativeToTodayDateRange(
                    configuration.createdAt,
                    nextAppointmentDateFrom,
                    nextAppointmentDateTo
                )

            filtersOverrides.nextAppointmentDateFrom = newDateFrom
                ? UtilHelper.dateToMysqlFormat(newDateFrom)
                : undefined

            filtersOverrides.nextAppointmentDateTo = newDateTo
                ? UtilHelper.dateToMysqlFormat(newDateTo)
                : undefined
        }

        return {
            ...configuration.filters,
            ...filtersOverrides,
        }
    }
}
